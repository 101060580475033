<template>
    <div v-if="form">

        <b-alert variant="primary" :show="form.submitted">
            <h4 class="alert-heading">
                Form Submitted
            </h4>
            <div class="alert-body">
                Form is opened in read only mode
            </div>
        </b-alert>

        <b-tabs ref="tabs" v-model="tabIndex">
            <b-tab
                v-for="(page, pageIndex) in form.pages"
                :key="pageIndex"
                :title="page.title"
                >
                <b-row>
                    <b-col
                    md="12"
                    xl="12"
                    class="mb-1"
                    >
                        <h1 v-if="page.title">{{page.title}}</h1>
                        <!-- page {{pageIndex}} -->

                    </b-col>
                </b-row>
                    
                <ol>
                    <div 
                        v-for="(question) in page.questions"
                        :key="question.key"
                        class="questions-section"
                    >
                        <li class="question-list">

                            <b-row v-if="question.type=='MultipleChoice'" class="pt-4 pb-4">
                                <!-- question -->
                                <b-col 
                                md="12"
                                xl="12"
                                >
                                    {{question.question}} *
                                    <feather-icon
                                        v-if="question.hint"
                                        icon="HelpCircleIcon"
                                        size="20"
                                        class="text-primary"
                                        v-b-popover.hover.top="`${question.hint}`"
                                    />
                                    <b-form-radio-group
                                        v-model="question.answer"
                                        :options="question.options"
                                        value-field="value"
                                        text-field="text"
                                        stacked
                                        :disabled="form.submitted"
                                    />
                                    <b-form-checkbox
                                        v-model="question.addComment"
                                        value="true"
                                        :disabled="form.submitted"
                                        class="mt-1"
                                        size="sm"
                                    >additional comments</b-form-checkbox>
                                    
                                    <b-form-input
                                        v-if="question.addComment"
                                        v-model="question.comment"
                                        type="text"
                                        :disabled="form.submitted"
                                    />
                                </b-col>
                            </b-row>
                            <b-row v-else-if="question.type=='Dropdown'" class="pt-4 pb-4">
                                <!-- question -->
                                <b-col 
                                md="12"
                                xl="12"
                                >
                                {{question.question}} *
                                <feather-icon
                                    v-if="question.hint"
                                    icon="HelpCircleIcon"
                                    size="20"
                                    class="text-primary"
                                    v-b-popover.hover.top="`${question.hint}`"
                                />
                                <v-select
                                    v-model="question.answer"
                                    label="text"
                                    :options="question.options" 
                                    :disabled="form.submitted"
                                />
                                <b-form-checkbox
                                    v-model="question.addComment"
                                    value="true"
                                    :disabled="form.submitted"
                                    class="mt-1"
                                    size="sm"
                                >additional comments</b-form-checkbox>
                                
                                <b-form-input
                                    v-if="question.addComment"
                                    v-model="question.comment"
                                    type="text"
                                    :disabled="form.submitted"
                                />

                                </b-col>
                            </b-row>
                            <b-row v-else-if="question.type=='DropdownMultiple'" class="pt-4 pb-4">
                                <!-- question -->
                                <b-col 
                                md="12"
                                xl="12"
                                >
                                {{question.question}} ( Select all options that apply ) *
                                <feather-icon
                                    v-if="question.hint"
                                    icon="HelpCircleIcon"
                                    size="20"
                                    class="text-primary"
                                    v-b-popover.hover.top="`${question.hint}`"
                                />
                                <v-select
                                    v-model="question.answer"
                                    multiple
                                    label="text"
                                    :options="question.options" 
                                    :disabled="form.submitted"
                                    :closeOnSelect="false"
                                />

                                <b-form-checkbox
                                    v-model="question.addComment"
                                    value="true"
                                    :disabled="form.submitted"
                                    class="mt-1"
                                    size="sm"
                                >additional comments</b-form-checkbox>
                                
                                <b-form-input
                                    v-if="question.addComment"
                                    v-model="question.comment"
                                    type="text"
                                    :disabled="form.submitted"
                                />
                                </b-col>
                            </b-row>
                            <b-row v-else-if="question.type=='Text'" class="pt-4 pb-4">
                                <!-- question -->
                                <b-col 
                                md="12"
                                xl="12"
                                >
                                {{question.question}} *
                                <feather-icon
                                    v-if="question.hint"
                                    icon="HelpCircleIcon"
                                    size="20"
                                    class="text-primary"
                                    v-b-popover.hover.top="`${question.hint}`"
                                />
                                <b-form-input
                                    v-model="question.answer"
                                    type="text"
                                    :disabled="form.submitted"
                                />
                                <b-form-checkbox
                                    v-model="question.addComment"
                                    value="true"
                                    :disabled="form.submitted"
                                    class="mt-1"
                                    size="sm"
                                >additional comments</b-form-checkbox>
                                
                                <b-form-input
                                    v-if="question.addComment"
                                    v-model="question.comment"
                                    type="text"
                                    :disabled="form.submitted"
                                />
                                </b-col>
                            </b-row>                        
                        </li>

                        <hr/>
                    </div>
                </ol>
                <div class="text-right">
                    <b-button-group class="mt-1">
                        <b-button
                            variant="outline-primary"
                            @click="changeIndex(-1)"
                        >
                            Previous
                        </b-button>
                        <b-button
                            variant="outline-primary"
                            @click="changeIndex(1)"
                        >
                            Next
                        </b-button>
                    </b-button-group>
                </div>  
                <div class="bottom-separation"> </div>
                
                <!-- <div class="w-100 d-flex" style="justify-content: space-between;">
                    <b-button v-b-modal.addQuestion variant="primary">Add Question</b-button>
                    <b-button variant="danger" @click="deleteSection">Delete Section</b-button>
                </div> -->
            </b-tab>
        </b-tabs>
   
        <div class="d-flex w-100 flex-row" v-if="!form.submitted"> 
            <b-button variant="primary" class="flex-grow-1 m-1" @click="saveDraft">Save Draft</b-button>
            <b-button variant="success" class="flex-grow-1 m-1" @click="submitQuestionnaire">Submit Questionnaire</b-button>
        </div>
</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BFormInput, BRow, BCol, BFormGroup, BProgress, BButton, BFormTextarea, BForm, BFormRadioGroup, BTabs, BTab, BButtonGroup, BAlert, VBPopover, BFormCheckbox } from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AxiosService from '@/Service/AxiosService'

import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
    components: {
        BFormRadioGroup,
        ValidationProvider,
        ValidationObserver,
        BFormInput,
        BFormGroup,
        BRow,
        BCol,
        BProgress,
        FormWizard,
        TabContent,
        BButton,
        vSelect, 
        BFormTextarea, 
        BForm,
        BTabs, 
        BTab,
        BButtonGroup, 
        BAlert,
        BFormCheckbox
    },
    directives: {
        'b-popover': VBPopover,
    },
    data(){
        return {
            form:null,
            tabIndex:0,
        }
    },
    methods:{
        changeIndex(value){
            this.tabIndex+=value;
            window.scrollTo({ top: 200, behavior: 'smooth' });
        },
        init(){
            AxiosService.get("/getEntityQuestionnaire").then(({error, form})=>{
                if(error){
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            variant: 'error',
                            text: `Some error occured while getting questionnaire, Please try again or contact an admin`,
                        },
                    })
                }
                else this.form = form;
            })
        },
        saveDraft(){
            AxiosService.post("/saveForm", {...this.form}).then(({error})=>{
                if(error){
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            variant: 'error',
                            text: `Some error occured while saving the questionnaire as draft, Please try again or contact an admin`,
                        },
                    })
                    // console.log("draft error")
                }
                else {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            variant: 'success',
                            text: `Questionnaire draft saved succesfully`,
                        },
                    })
                    // console.log("draft saved")
                }
            })
        },
        submitQuestionnaire(){       
            for(let page of this.form.pages){
                for(let question of page.questions){
                    if(!question.answer) {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                variant: 'warning',
                                text: `Some fields are missing`,
                            },
                        })
                        return;
                    }
                }
            }

            AxiosService.post("/saveForm", {...this.form, submitted:true}).then(({error})=>{
                if(error){
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            variant: 'error',
                            text: `Some error occured while submitting the questionnaire, Please try again or contact an admin`,
                        },
                    })
                }
                else {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            variant: 'success',
                            text: `Questionnaire submitted succesfully`,
                        },
                    })
                    this.init();
                }
            })
        },
        
    },
    mounted(){
        this.init();
    }
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>
    .bottom-separation{
        border-bottom: 1px solid rgba(34, 41, 47, 0.08);
        margin: 0 -20px 10px;
        border-color: #3b4253;
    }
</style>